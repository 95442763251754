import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <AppBar position="fixed" sx={{ backgroundColor: 'black', top: 'auto', bottom: 0, padding: '10px 0' }}>
            <Toolbar sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ textAlign: isMobile ? 'center' : 'left' }}>
                    <Typography variant="body1">
                        <span title="Todos los derechos reservados">©</span> {new Date().getFullYear()} CTRL-Z
                    </Typography>
                </Box>
                <Box sx={{ mt: isMobile ? 1 : 0, textAlign: isMobile ? 'center' : 'right' }}>
                    <Typography variant="body1">
                        contacto@ctrl-z.cl
                    </Typography>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Footer;
