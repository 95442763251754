import React, { createContext, useState, useEffect } from 'react';

const AppContext = createContext();

const AppProvider = ({ children }) => {
    const [state, setState] = useState({
        user: null,
        theme: 'light',
    });

    useEffect(() => {
        console.log('AppProvider rendered with state:', state);
    }, [state]);

    const login = (user) => {
        setState((prevState) => ({
            ...prevState,
            user,
        }));
    };

    const logout = () => {
        setState((prevState) => ({
            ...prevState,
            user: null,
        }));
    };

    const toggleTheme = () => {
        setState((prevState) => ({
            ...prevState,
            theme: prevState.theme === 'light' ? 'dark' : 'light',
        }));
    };

    return (
        <AppContext.Provider value={{ state, login, logout, toggleTheme }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppProvider };
