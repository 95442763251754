import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Menu from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import ContactModal from './ContactModal';
import logo from '../assets/images/logo.png';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';

const Navbar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [menuOpen, setMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toggleMenuOpen = () => {
        setMenuOpen(!menuOpen);
    };

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleSubmenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSubmenuClose = () => {
        setAnchorEl(null);
    };

    const mobileMenu = (
        <Dialog
            fullScreen
            open={menuOpen}
            onClose={toggleMenuOpen}
            PaperProps={{
                sx: {
                    backgroundColor: 'black',
                    color: 'white',
                },
            }}
        >
            <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={toggleMenuOpen}
                sx={{ position: 'absolute', top: 16, right: 16 }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ paddingTop: '80px' }}>
                <List>
                    <ListItem button component={Link} to="/about" onClick={toggleMenuOpen}>
                        <ListItemText primary="Sobre Nosotros" />
                    </ListItem>
                    <ListItem button component={Link} to="/advanced-technology" onClick={toggleMenuOpen}>
                        <ListItemText primary="Soluciones Tecnológicas Avanzadas" />
                    </ListItem>
                    <ListItem button component={Link} to="/smart-home" onClick={toggleMenuOpen}>
                        <ListItemText primary="Domótica de Última Generación" />
                    </ListItem>
                    <ListItem button component={Link} to="/operational-improvements" onClick={toggleMenuOpen}>
                        <ListItemText primary="Mejoras Operativas" />
                    </ListItem>
                    <ListItem sx={{ justifyContent: 'center' }}> {/* Centrar el botón */}
                        <Button
                            color="primary"
                            variant="contained"
                            href="https://app.ctrl-z.cl"
                            sx={{ fontSize: '0.8rem', padding: '6px 12px' }}
                            onClick={toggleMenuOpen}
                        >
                            Iniciar Sesión
                        </Button>
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>
    );

    const desktopSubmenu = (
        <Popover
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleSubmenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            PaperProps={{
                style: { backgroundColor: 'black', color: 'white' },
            }}
        >
            <MenuList>
                <MenuItem component={Link} to="/advanced-technology" onClick={handleSubmenuClose} sx={{ color: 'white' }}>
                    Soluciones Tecnológicas Avanzadas
                </MenuItem>
                <MenuItem component={Link} to="/smart-home" onClick={handleSubmenuClose} sx={{ color: 'white' }}>
                    Domótica de Última Generación
                </MenuItem>
                <MenuItem component={Link} to="/operational-improvements" onClick={handleSubmenuClose} sx={{ color: 'white' }}>
                    Mejoras Operativas
                </MenuItem>
            </MenuList>
        </Popover>
    );

    return (
        <>
            <AppBar position="fixed" sx={{ backgroundColor: 'black', top: 0, left: 0, right: 0, zIndex: 1300 }}>
                <Toolbar sx={{ padding: 0 }}>
                    <Link to="/" style={{ flexGrow: 1 }}>
                        <img src={logo} alt="CTRL-Z Logo" style={{ height: '40px' }} />
                    </Link>
                    {isMobile ? (
                        <>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleMenuOpen}
                            >
                                <Menu />
                            </IconButton>
                            {mobileMenu}
                        </>
                    ) : (
                        <>
                            <Button color="inherit" component={Link} to="/about">
                                Sobre Nosotros
                            </Button>
                            <Button
                                color="inherit"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleSubmenuOpen}
                            >
                                Soluciones
                            </Button>
                            {desktopSubmenu}
                            <Button color="inherit" onClick={handleModalOpen}>
                                Contáctanos
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                href="https://app.ctrl-z.cl"
                                sx={{ ml: 2, fontSize: '0.8rem', padding: '6px 12px' }}
                            >
                                Iniciar Sesión
                            </Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            <ContactModal open={modalOpen} handleClose={handleModalClose} />
        </>
    );
};

export default Navbar;
