import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar.jsx';
import Footer from './components/Footer.jsx';

const Home = React.lazy(() => import('./pages/Home.jsx'));
const About = React.lazy(() => import('./pages/About.jsx'));
const AdvancedTechnology = React.lazy(() => import('./pages/AdvancedTechnology.jsx'));
const SmartHome = React.lazy(() => import('./pages/SmartHome.jsx'));
const OperationalImprovements = React.lazy(() => import('./pages/OperationalImprovements.jsx'));

function App() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar />
      <div style={{ flex: 1, marginTop: '80px' }}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/advanced-technology" element={<AdvancedTechnology />} />
            <Route path="/smart-home" element={<SmartHome />} />
            <Route path="/operational-improvements" element={<OperationalImprovements />} />
          </Routes>
        </React.Suspense>
      </div>
      <Footer />
    </div>
  );
}

export default App;
