import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import emailjs from 'emailjs-com';

const ContactModal = ({ open, handleClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [messageSent, setMessageSent] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const templateParams = {
            to_name: 'CTRL-Z',
            from_name: formData.name,
            from_email: formData.email,
            message: formData.message,
        };

        emailjs.send(
            'service_2leyryq',  // Service ID actualizado
            'template_5ksny8m',  // Template ID
            templateParams,
            'flcaHPtcbc5nv9Y5B'  // Public Key
        ).then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setMessageSent(true);
            setTimeout(() => {
                setMessageSent(false);
                handleClose();
            }, 2000);
        }).catch((err) => {
            console.log('FAILED...', err);
            console.error('Error details:', err.status, err.text);
        });
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: 300, sm: 400 },
                bgcolor: '#333',  // Fondo gris oscuro
                color: '#fff',
                boxShadow: 24,
                borderRadius: 2,
                p: 4,
            }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center' }}>
                    Contáctanos
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Nombre"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.name}
                        onChange={handleChange}
                        InputLabelProps={{ style: { color: '#ccc' } }}
                        InputProps={{ style: { color: '#fff', borderColor: '#555' } }}
                    />
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Correo Electrónico"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.email}
                        onChange={handleChange}
                        InputLabelProps={{ style: { color: '#ccc' } }}
                        InputProps={{ style: { color: '#fff', borderColor: '#555' } }}
                    />
                    <TextField
                        id="message"
                        name="message"
                        label="Mensaje"
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.message}
                        onChange={handleChange}
                        InputLabelProps={{ style: { color: '#ccc' } }}
                        InputProps={{ style: { color: '#fff', borderColor: '#555' } }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        fullWidth
                        sx={{
                            backgroundColor: '#1976d2',
                            '&:hover': {
                                backgroundColor: '#125a8c',
                            },
                            marginTop: 2,
                        }}
                    >
                        Enviar
                    </Button>
                </form>
                {messageSent && (
                    <Typography variant="body1" sx={{ mt: 2, textAlign: 'center', color: '#4caf50' }}>
                        ¡Mensaje enviado con éxito!
                    </Typography>
                )}
            </Box>
        </Modal>
    );
};

export default ContactModal;
